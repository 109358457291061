import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { ModalService } from './modules/shared/modal';
import { Subscription } from 'rxjs';
import { PubsubService } from './services/pubsub.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginuserdetailsService } from './services/loginuserdetails.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AppConfig } from './services/appconfig.service';
import { UtilityService } from './services/utility.service';
import { NotifierService } from 'angular-notifier';




//import {loginUserWalkMe} from './assets/js/loadLoginUserForWalkme.js';
declare var setUserForWalkme: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'HCRWebApp';
  currentUrl: string;
  routes: Array<Object>;
  alerts: any[];
  enablepopup: boolean = false;
  highseveritypopuplistcount: number;
  highalertssubscription: Subscription;
  highAlertCount: any;

  //Session management
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  showSessionTimeoutPopup = false;
  formnotifier: any;
  submitText: any;
  approveText: any;
  rejectText: any;


  constructor( private modalService: ModalService,
    private pubsub: PubsubService, private notifier: NotifierService, private spinner: NgxSpinnerService, 
    private userloginservice: LoginuserdetailsService, private idle: Idle, private keepalive: Keepalive,
    private router: Router, private adal: MsAdalAngular6Service, private utility: UtilityService,
   
    ) {
  
    console.log("Flow Track => app.component.ts is loaded")
    // this.getFormSubmitNotifierflag();
    console.log("app component contructor")
    //Session management
    // sets an idle timeout of 600 seconds (10 minutes).
    idle.setIdle(1200);
    // sets a timeout period of 5 seconds. after 0 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      console.log(this.idleState);
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = `Your session expired! 
      Clicking on \'Reload\' will redirect you to home page.`;
      this.timedOut = true;
      this.showSessionTimeoutPopup = true;
      console.log(this.idleState);
      //this.router.navigate(['/refresh']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      console.log(this.idleState);
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      console.log(this.showSessionTimeoutPopup);
      //this.showSessionTimeoutPopup = true;
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.idleState);

    });

    this.pubsub.sessionExtentionNotification().subscribe(
      message => {
        //console.log("Session extended on request");
        this.reset();
      }
    );

    setInterval(() => {
      if (this.timedOut == false) {
        this.adal.RenewToken(AppConfig.settings.adalConfig.endpoints.api);
        console.log("AD token renewed!  " + Date().toString());
        this.utility.PingAdminService();
        //this.getHighSeverityAlerts();
        console.log("Pinged service " + Date().toString());
      }
    }
      , 3300 * 1000); // 55 minutes

    // sets the ping interval to 60 seconds
    keepalive.interval(60);

    keepalive.onPing.subscribe(() => { this.lastPing = new Date(); });

    this.reset();
   

  }


  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  stay(modelPopuId) {
    this.showSessionTimeoutPopup = false;
    this.reset();
  }

  reload(modelPopuId) {
    this.showSessionTimeoutPopup = false;
    //this.router.navigate(['/refresh']);
    window.location.href = window.location.origin;
  }

  ngOnInit() {
    console.log("FlowTrack => I am Onit from app.component.ts")

  }
  closeModalDialog(Action, obj) {
    var userAlias = this.userloginservice.GetUserAlias();
    var parameter = obj.map(x => x.id);
   

    this.modalService.close(Action);
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {

  }
  ngOnDestroy() {
    this.idle.onIdleEnd.unsubscribe();
    this.idle.onTimeout.unsubscribe();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    console.log("app component destroy");
  }

}
