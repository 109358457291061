import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

interface Scripts {
  name: string;
  src: string;
}  

@Injectable({
  providedIn: 'root'
})
export class SharedService 

{

  apiData: any;

  constructor(private _http: HttpClient, private spinnerService: NgxSpinnerService) { }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  }

  generateExcel(fileName: string, sheetName: string, columns: string[], data: any[]) {
    //debugger;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName, {
      views: [
        { state: 'frozen', ySplit: 1 }
      ]
    });
    let topRow = worksheet.addRow(columns);
    // topRow.font = {
    //   name: 'Calibri',
    //   size: 16,
    //   underline: 'single',
    //   bold: true,
    //   color: { argb: '0085A3' }
    // }
    topRow.font = {
      bold: true,
    }
    topRow.eachCell(
      cell => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
        cell.alignment = {
          vertical: 'top',
          horizontal: 'left',
          wrapText: true
        }
      }
    );

    data.forEach(d => {
      //console.log(d);
      let row = worksheet.addRow(Object.values(d));
      row.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
      row.eachCell(
        cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.alignment = {
            vertical: 'top',
            horizontal: 'left',
            wrapText: true
          }
        }
      )
    });

    workbook.xlsx.writeBuffer().then((x) => {
      let blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, fileName + '.xlsx');
      this.spinnerService.hide();
    })

  }
  

  adobeAnalyticsCall(pageName: string, userID: string, applicationName:string) {
  //   //debugger;
    var analyticsDataLayer =
    {
   "pageInfo": 
   {"pageName": pageName,
   "userID": userID, 
   "applicationName": applicationName 
   }
   };
  // _satellite.track('pageview);

}

}


//import {ScriptStore} from "./script.store";

declare var document: any;

@Injectable()
export class ScriptService {


  // type MyArrayType = Array<{id: number, text: string}>;

  // const arr: MyArrayType = [
  //     {id: 1, text: 'Sentence 1'},
  //     {id: 2, text: 'Sentence 2'},
  //     {id: 3, text: 'Sentence 3'},
  //     {id: 4, text: 'Sentenc4 '},
  // ];


private scripts: any = {};

 
  ScriptStore: Scripts[] = [
  {name: 'adobeDependency', src:  '_satellite.track("pageview")'},
  ];

constructor() {
    this.ScriptStore.forEach((script: any) => {
        this.scripts[script.name] = {
            loaded: false,
            src: script.src
        };
    });
}

load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
}

loadScript(name: string) {
    return new Promise((resolve, reject) => {
        //resolve if already loaded
        if (this.scripts[name].loaded) {
            resolve({script: name, loaded: true, status: 'Already Loaded'});
        }
        else {
            //load script
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = this.scripts[name].src;
            if (script.readyState) {  //IE
                script.onreadystatechange = () => {
                    if (script.readyState === "loaded" || script.readyState === "complete") {
                        script.onreadystatechange = null;
                        this.scripts[name].loaded = true;
                        resolve({script: name, loaded: true, status: 'Loaded'});
                    }
                };
            } else {  //Others
                script.onload = () => {
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                };
            }
            script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    });
}

}
