import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import {Observable, Subscriber} from 'rxjs'

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {

  @Input("totalRecordCount") totalRecordCount: number = 0;
  @Input("pageSize") pageSize: number = 0;
  @Output("loadPageData") loadPageData: EventEmitter<any> = new EventEmitter();

  dotPossition: number = 0;
  /*
    1 = front
    2 = back
    3 = both front and back
  */
  pageNumbersToBeDisplayed: number[] = [];
  pageNumbersToBeHighlighted: number[] = [1];



  pageCount: number = 0;
  pages: number[] = [];
  startPageSet: number[] = [];
  endPageSet: number[] = [];
  displayingRecordsFrom: number = 1;
  displayingRecordsTo: number = 0;
  readonly pageCountTobeDisplayed: number = 5;
  txtPageNo: any = '';

  constructor(private notifier: NotifierService) { }

  ngOnInit() { 
    this.updatePagination();
  }

  ngOnChanges(changes: SimpleChanges){
    //alert('Inside ngOnChanges');
    //console.log(JSON.stringify(changes));
    this.updatePagination();
  }

  ngOnDestroy(){
    //alert('OnDestroy called!');
  }

  changePage(event: any, pageNumber?: any) {
    //console.log(event);
    //debugger;

    if (event instanceof MouseEvent) {
      if (this.pageNumbersToBeDisplayed.includes(parseInt(pageNumber))) {
        this.pageNumbersToBeHighlighted = [parseInt(pageNumber)];
        this.pageChanged(pageNumber, false);
      }
    }
    else {

      if (isNaN(this.txtPageNo) || this.txtPageNo < 1 || this.txtPageNo > this.pageCount) {
        this.notifier.notify("error", "Invalid page number.");
        return false;
      }
      else {
        this.txtPageNo = parseInt(this.txtPageNo);

        // First set
        if (this.pageCount <= this.pageCountTobeDisplayed) {
          this.pageNumbersToBeHighlighted = [this.txtPageNo];
          this.pageChanged(this.txtPageNo, false);
        }

        //Current loaded page numbers
        else if (this.pageNumbersToBeDisplayed && this.pageNumbersToBeDisplayed.includes(this.txtPageNo)) {
          this.pageNumbersToBeHighlighted = [this.txtPageNo];
          this.pageChanged(this.txtPageNo, false);
        }

        //Elsewhere
        else {
          let currentDisplayedMinPageNumber: number = this.pageNumbersToBeDisplayed.sort((a: number, b: number) => a - b)[0];
          
          if(!currentDisplayedMinPageNumber){
            currentDisplayedMinPageNumber = this.pageNumbersToBeHighlighted[0];
          }

          if (this.txtPageNo == currentDisplayedMinPageNumber) {
            this.pageNumbersToBeHighlighted = [this.txtPageNo];
            this.pageChanged(this.txtPageNo, false);
          }

          else if (this.txtPageNo < currentDisplayedMinPageNumber) {

            let currentLoadedPageset: number = this.pageNumbersToBeDisplayed[0] || currentDisplayedMinPageNumber;
            let requestedSet: number = currentLoadedPageset - this.pageCountTobeDisplayed;
            let pageSet: number[] = [];
            pageSet = this.pages.slice(requestedSet - 1, currentLoadedPageset - 1);

            while (true) {

              if (requestedSet <= this.pages[0]) {
                this.dotPossition = 2;
                this.pageNumbersToBeDisplayed = this.pages.slice(1, this.pageCountTobeDisplayed);
                this.pageNumbersToBeHighlighted = [this.txtPageNo];
                this.pageChanged(this.txtPageNo, false);
                break;
              }
              else if (pageSet.includes(this.txtPageNo) && pageSet[0] <= this.pageCountTobeDisplayed) {
                this.dotPossition = 2;
                this.pageNumbersToBeDisplayed = this.pages.slice(1, this.pageCountTobeDisplayed);
                this.pageNumbersToBeHighlighted = [this.txtPageNo];
                this.pageChanged(this.txtPageNo, false);
                break;
              }
              else if (pageSet.includes(this.txtPageNo) && pageSet[0] > this.pageCountTobeDisplayed) {
                this.dotPossition = 3;
                this.pageNumbersToBeDisplayed = pageSet;
                this.pageNumbersToBeHighlighted = [this.txtPageNo];
                this.pageChanged(this.txtPageNo, false);
                break;
              }

              currentLoadedPageset = pageSet[0];
              requestedSet = currentLoadedPageset - this.pageCountTobeDisplayed;
              pageSet = this.pages.slice(requestedSet - 1, currentLoadedPageset - 1);
            }
          }
          else if (this.txtPageNo > currentDisplayedMinPageNumber) {

            let currentLoadedPageset: number = this.pageNumbersToBeDisplayed[this.pageNumbersToBeDisplayed.length - 1];
            let requestedSet: number = currentLoadedPageset + this.pageCountTobeDisplayed;

            let pageSet: number[] = [];
            pageSet = this.pages.slice(currentLoadedPageset, requestedSet);

            while (true) {

              if (requestedSet >= this.pageCount) {
                this.dotPossition = 1;
                this.pageNumbersToBeDisplayed = this.endPageSet;;
                this.pageNumbersToBeHighlighted = [this.txtPageNo];
                this.pageChanged(this.txtPageNo, false);
                break;
              }
              else if (pageSet.includes(this.txtPageNo) && pageSet[pageSet.length - 1] < this.pageCount) {
                this.dotPossition = 3;
                this.pageNumbersToBeDisplayed = pageSet;
                this.pageNumbersToBeHighlighted = [this.txtPageNo];
                this.pageChanged(this.txtPageNo, false);
                break;
              }
              else if (pageSet.includes(this.txtPageNo) && pageSet[pageSet.length - 1] == this.pageCount) {
                this.dotPossition = 1;
                this.pageNumbersToBeDisplayed = pageSet;
                this.pageNumbersToBeHighlighted = [this.txtPageNo];
                this.pageChanged(this.txtPageNo, false);
                break;
              }

              currentLoadedPageset = pageSet[pageSet.length - 1];
              requestedSet = currentLoadedPageset + this.pageCountTobeDisplayed;
              pageSet = this.pages.slice(currentLoadedPageset, requestedSet);
            }
          }
        }
        this.txtPageNo = "";
      }
    }
  }

  jumpToNextPage(isLoadMoreClicked: boolean = false) {
    //console.log('jumpToNextPage');
    //debugger;
    this.txtPageNo = "";

    let currentLoadedPageNumber: number = this.pageNumbersToBeHighlighted.sort((a: number, b: number) => a - b)[this.pageNumbersToBeHighlighted.length - 1];
    let requrestedPageNumber: number = currentLoadedPageNumber + 1;

    if (this.pages.includes(requrestedPageNumber)) {
      if (this.pageCount <= this.pageCountTobeDisplayed || this.pageNumbersToBeDisplayed.includes(requrestedPageNumber)) {

        if (!isLoadMoreClicked) {
          this.pageNumbersToBeHighlighted = [requrestedPageNumber];
        }
        else {
          this.pageNumbersToBeHighlighted.push(requrestedPageNumber);
        }
        this.pageChanged(requrestedPageNumber, isLoadMoreClicked);
      }
      else {
        this.jumpToNextSetOfPages(isLoadMoreClicked);
      }
    }

  }

  jumpToNextSetOfPages(isLoadMoreClicked: boolean = false) {
    //console.log('jumpToNextPage');
    //debugger;

    let currentLoadedPageset: number = this.pageNumbersToBeDisplayed[this.pageNumbersToBeDisplayed.length - 1];
    let requestedSet: number = currentLoadedPageset + this.pageCountTobeDisplayed;

    if (this.pages.includes(requestedSet) && requestedSet < this.pages.length) {
      this.pageNumbersToBeDisplayed = [];
      this.pageNumbersToBeDisplayed = this.pages.slice(currentLoadedPageset, requestedSet);
      this.dotPossition = 3;

      if (!isLoadMoreClicked) {
        this.pageNumbersToBeHighlighted = [];

      }
      this.pageNumbersToBeHighlighted.push(this.pageNumbersToBeDisplayed[0]);
      this.pageChanged(this.pageNumbersToBeDisplayed[0], isLoadMoreClicked);
    }
    else {
      this.jumToEndPageSet(currentLoadedPageset + 1, isLoadMoreClicked);
    }
  }

  jumpToPreviousPage() {
    //console.log('jumpToPreviousPage');
    //debugger;

    let currentLoadedPageNumber: number = this.pageNumbersToBeHighlighted.sort((a: number, b: number) => a - b)[this.pageNumbersToBeHighlighted.length - 1];
    let requrestedPageNumber: number = currentLoadedPageNumber - 1;

    if (this.pages.includes(requrestedPageNumber)) {
      if (this.pageNumbersToBeDisplayed.includes(requrestedPageNumber)) {
        this.pageNumbersToBeHighlighted = [];
        this.pageNumbersToBeHighlighted = [requrestedPageNumber];
        this.pageChanged(this.pageNumbersToBeHighlighted[0], false);
      }
      else if (requrestedPageNumber > this.pages[0]) {
        this.jumpToPreviousSetOfPages();
      }
      else {
        this.pageNumbersToBeHighlighted = [this.pages[0]];
        this.pageChanged(this.pageNumbersToBeHighlighted[0], false);
      }
    }
  }

  jumpToPreviousSetOfPages() {
    //console.log('jumpToPreviousSetOfPages');
    //debugger;
    let currentLoadedPageset: number = this.pageNumbersToBeDisplayed[0];

    if(!currentLoadedPageset){
      currentLoadedPageset = this.pageNumbersToBeHighlighted[0];
    }

    let requestedSet: number = currentLoadedPageset - this.pageCountTobeDisplayed;

    //if(this.pages.includes(requestedSet)){
    //if (requestedSet != this.pages[0]) {
    if (requestedSet > this.pages[0]) {
      this.pageNumbersToBeDisplayed = [];
      this.pageNumbersToBeDisplayed = this.pages.slice(requestedSet - 1, currentLoadedPageset - 1);
      this.dotPossition = 3;
      this.pageNumbersToBeHighlighted = [];
      this.pageNumbersToBeHighlighted.push(this.pageNumbersToBeDisplayed[this.pageNumbersToBeDisplayed.length - 1]);
      this.pageChanged(this.pageNumbersToBeHighlighted[0], false);
    }
    else {
      this.jumpToStartPageSet(true, currentLoadedPageset - 1);
    }
  }

  jumpToStartPageSet(updateGridData: boolean, highlightPageNumber?: number,) {
    //console.log('jumpToStartPageSet');
    //debugger;

    if (this.pageCount <= this.pageCountTobeDisplayed) {
      this.dotPossition = 0;
      this.startPageSet = Array.from(this.pages);
      //removing first and last elements
      this.startPageSet.shift();
      this.startPageSet.pop();
      this.endPageSet = Array.from(this.startPageSet);
    }
    else if (this.pageCount > this.pageCountTobeDisplayed) {
      this.dotPossition = 2;
      this.startPageSet = this.pages.slice(1, this.pageCountTobeDisplayed);

      if ((this.pages.length % this.pageCountTobeDisplayed) == 0) {
        this.endPageSet = this.pages.slice((this.pages.length - this.pageCountTobeDisplayed), (this.pages.length - 1));
      }
      else {
        //this.endPageSet = this.pages.slice(this.pages.length-(Math.ceil(this.pages.length+(this.pages.length / this.pageCountTobeDisplayed))-1));
        this.endPageSet = (this.pages.reverse()).slice(1, (this.pages.length % this.pageCountTobeDisplayed));
        this.endPageSet.sort((a, b) => a - b);
        this.pages.sort((a, b) => a - b);
      }
    }

    //this.pageNumbersToBeDisplayed = this.pages.slice(1, this.pageCountTobeDisplayed);    

    if (highlightPageNumber) {
      this.pageNumbersToBeHighlighted = [highlightPageNumber];
      //this.pageChanged(this.pageNumbersToBeHighlighted[0], false);
    }
    else {
      this.pageNumbersToBeHighlighted = [this.pages[0]];
      //this.pageChanged(this.pageNumbersToBeHighlighted[0], false);
    }

    if (updateGridData) {
      this.pageChanged(this.pageNumbersToBeHighlighted[0], false);
    }

    this.pageNumbersToBeDisplayed = this.startPageSet;
    // this.pageChanged(this.pageNumbersToBeHighlighted[0], false);
  }

  jumToEndPageSet(highlightPageNumber?: number, isLoadMoreClicked: boolean = false) {
    //console.log('jumToEndPageSet');
    //debugger;

    if (this.pageCount <= this.pageCountTobeDisplayed) {
      this.dotPossition = 0;
    }
    else if (this.pageCount > this.pageCountTobeDisplayed) {
      this.dotPossition = 1;
    }


    if (highlightPageNumber && isLoadMoreClicked) {
      this.pageNumbersToBeHighlighted.push(highlightPageNumber);
      this.pageChanged(highlightPageNumber, isLoadMoreClicked);
    }
    else if (highlightPageNumber) {
      this.pageNumbersToBeHighlighted = [highlightPageNumber];
      this.pageChanged(highlightPageNumber, isLoadMoreClicked);
    }
    else {
      this.pageNumbersToBeHighlighted = [this.pages.length];
      this.pageChanged(this.pages.length, isLoadMoreClicked);
    }

    //this.pageNumbersToBeDisplayed = this.pages.slice((this.pages.length - this.pageCountTobeDisplayed), (this.pages.length - 1));
    this.pageNumbersToBeDisplayed = this.endPageSet;

  }

  pageChanged(pageNumber: number, isLoadMoreClicked: boolean = false) {
    //// debugger;
    this.loadPageData.emit({ 'pageNumber': pageNumber, 'isLoadMoreClicked': isLoadMoreClicked });
    this.updateDisplayRecordCount(pageNumber, isLoadMoreClicked);
  }

  updateDisplayRecordCount(pageNumber: number, isLoadMoreClicked: boolean) {
    //debugger;
    let rowStart: number = 0;
    let rowEnd: number = 0;

    if (isLoadMoreClicked) {
      rowStart = this.displayingRecordsFrom;
      rowEnd = this.displayingRecordsTo + this.pageSize;
    }
    else {
      pageNumber = pageNumber - 1;
      rowStart = this.pageSize * pageNumber + 1;
      rowEnd = rowStart + this.pageSize - 1;
    }

    if (rowEnd > this.totalRecordCount) {
      rowEnd = this.totalRecordCount;
    }

    this.displayingRecordsFrom = rowStart;
    this.displayingRecordsTo = rowEnd;
  }
  
  checkPageContainsGivenPageData(givenPageNumber : number){
    //debugger;
    return this.pageNumbersToBeHighlighted.includes(givenPageNumber);
  }

  // callfromEMSPackages(recordcount: number){
  //   //debugger;
  //   this.pageCount=0;
  //   this.pages=[];
  //   if (recordcount > this.pageSize) {
  //     this.pageCount = Math.ceil(recordcount / this.pageSize);
  //     this.pages = Array.from(Array(this.pageCount).keys(), (_, i) => i + 1);

  //     if (this.pages && this.pages.length > 1) {
  //       this.jumpToStartPageSet(false);
  //     }
  //   }
  //   this.displayingRecordsFrom =1;
  //   this.displayingRecordsTo = this.pageSize;
  //   this.txtPageNo ='';
  // }
  
  updatePagination(){

    this.resetValues();

    if (this.totalRecordCount > this.pageSize) {
      this.pageCount = Math.ceil(this.totalRecordCount / this.pageSize);
      this.pages = Array.from(Array(this.pageCount).keys(), (_, i) => i + 1);

      if (this.pages && this.pages.length > 1) {
        this.jumpToStartPageSet(false);
      }
    }
    this.displayingRecordsTo = this.pageSize;
  }

  resetValues(){
    this.pageCount = 0;
    this.pages = [];
    this.startPageSet= [];
    this.endPageSet = [];
    this.displayingRecordsFrom = 1;
    this.displayingRecordsTo = 0;
    this.txtPageNo = '';
  }
}

