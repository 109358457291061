import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private apiData: any;
  constructor(private _http: HttpClient) { }

  public PingAdminService(): void {
  
    this._http.get<any[]>("utility/ping").subscribe();
    //return this.apiData;
}
}
