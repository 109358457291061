import { finalize, tap } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

    count = 0;
    private requests: HttpRequest<any>[] = [];
    constructor(private spinner: NgxSpinnerService) {
        
    }
  removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
    
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        //console.log("No of requests--->" + this.requests.length);
        if( this.requests.length > 0)
        this.spinner.show();
        return Observable.create(observer => {
          const subscription = next.handle(req).pipe(
            finalize(() => {
              if (this.requests.length == 0) {
                this.spinner.hide();
              }
            }))
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            this.removeRequest(req);
                            observer.next(event);
                        }
                    },
                    err => {
                        this.removeRequest(req);
                        observer.error(err);
                    },
                    () => {
                        this.removeRequest(req);
                        observer.complete();
                    });
            // remove request from queue when cancelled
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
