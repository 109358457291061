import { bool } from 'aws-sdk/clients/signer';

export class UserActions {
    roleId: number = 0;
    roleDescription: string = '';
    canSearchArchives: boolean = false;
    canAccessArchives: boolean = false;
    canCreateArchive: boolean = false;
    canManageArchiveInfo: boolean = false;
    canManageAccess: boolean = false;
    canSubmit: boolean = false;
    canUpload: boolean = false;
    canDownload: boolean = false;
    canDelete: boolean = false;
    canMarkAsInactive: boolean = false;
    canManageERP: boolean = false;
    canRequestRetentionException: boolean = false;
    canRequestAccess: boolean = false;
    canApproveTemporaryAccess: boolean = false;
    canViewArchiveHistory: boolean = false;
    canPrintBinderReportCovers: boolean = false;
    canRequestImage: boolean = false;
    canLinkArchives: boolean = false;
    canApprove: boolean = false;
    canResubmit: boolean = false;
    canResubmitAdminApproval: boolean = false;
    canRequestArchiveDeletion: boolean = false;
    canRequestForm3283S: boolean = false;
    canApproveForm3283S: boolean = false;
    canRequestLegalHold: boolean = false;
    canTempArchiveAccess: boolean = false;
    canMarkRestrictedAccess: boolean = false;
    canViewSwiftEngagementDetails: boolean = false;
    canChangeEstimatedReleaseDate: boolean = false;
}