import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';

import {HomePageService} from '../../services/homepage.service'
import { PubsubService } from '../../services/pubsub.service';
import { Subscription } from 'rxjs';
import { isNumber } from 'util';
import { UserConfigSettingService } from '../../guards/user-role-guard.service';

import { ModalService } from '../../modules/shared/modal';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit,OnDestroy {

  currentUrl: string;
  UserName = this.adalSvc.LoggedInUserName;
  IsClicked: any;
  IsClickedArrow:boolean;
  IsClickedhelp:boolean;
  alerts: any;
  highSeverityalerts: any;
  readreceipents: any;
  count:number;
  IsDisable:boolean=false;
  userprofilepicurl : string;
  userEmail = this.adalSvc.LoggedInUserEmail;
  userAlias = this.userEmail.substring(0, this.userEmail.lastIndexOf("@"));
  imageText: boolean;
  // dataRefresher: NodeJS.Timer;
  dataRefresher:any;
  highseveritypopuplistcount: number;
  userConfig: any;

  number: any;
  subscription: Subscription;
  normalalertsubscription: Subscription;
  highalertssubscription : Subscription;
  normalAlertCount: number =0;
  highAlertCount: number =0;
  Alertscount: number = 0;
  homepageSvc : any;
  constructor(private router: Router, private adalSvc: MsAdalAngular6Service,
    private modalService: ModalService,
    //private homepageSvc: HomePageService,
    private homepageService: HomePageService,
    private _userConfig:UserConfigSettingService,
    private pubsub:PubsubService) {
    router.events.subscribe((_: NavigationEnd) => this.currentUrl = this.router.url);
    this.userConfig = this._userConfig.FetchLoggedInUser(this.userAlias);
    this.homepageSvc =  homepageService;
  }
  clickOutside() {
    this.IsClickedArrow = !this.IsClickedArrow;
  }
  clickOutsideforDoc() {
    this.IsClickedhelp = !this.IsClickedhelp;
  }
  alertscount(){
   this.normalalertsubscription =this.pubsub.getNormalAlertsCount().subscribe(
     message =>{
       if(!isNaN(message.text) && isNumber(message.text)){
        this.normalAlertCount =  message.text;
        this.Alertscount = this.normalAlertCount + this.highAlertCount ;
        if(this.Alertscount >0){
          this.IsDisable = true;
        }
        else{
          this.IsDisable = false;
        }
       }
       else{
         console.log(message.text);
       }
     }
   );

   this.highalertssubscription = this.pubsub.getHighAlertsCount().subscribe(
     message =>{
       if(!isNaN(message.text) && isNumber(message.text)){
        this.highAlertCount =  message.text;
        this.Alertscount = this.normalAlertCount + this.highAlertCount ;
        if(this.Alertscount >0){
          this.IsDisable = true;
        }
        else{
          this.IsDisable = false;
        }
       }
       else{
        console.log(message.text);
      }
     }
   );

  }


  ngOnInit() {
    this.imageText=false;
    this.userprofilepicurl = "https://people.m.us.deloitte.com/N/people/Photo/"+this.userAlias+"_MThumb_S";

    this.userConfig = this._userConfig.FetchLoggedInUser(this.userAlias);
  }

  OnClickArrow() {
    this.IsClickedArrow = true;
    this.IsClickedhelp=false;
  }
  OnClickHelp(){
    this.IsClickedhelp=true;
    this.IsClickedArrow = false;
  }
  OnClickAdmin(PageUrl) {
    this.homepageSvc.advanceSearchBtnDisable=false;
    this.homepageSvc.simpleSearchVisible=false;
    this.router.navigate(["/" + PageUrl + ""]);
    this.IsClickedArrow = false;
  }
  logout(){
    // this.modalService.openWithCustomWidth( 'ConfirmLogout', "450");
    this.adalSvc.logout();
   }
  //  closewindow(){
  //   window.history.forward();
  //   localStorage.clear();
  //   window.open('about:blank', '_self', '');
  //   window.close();
  //  }
  //  closepopup(){
  //   this.modalService.close('ConfirmLogout');
  //   this.IsClickedArrow = false;
  //  }
  closeModalDialog(pagename) {

  if (pagename=='Arrow'){
    this.IsClickedArrow=false;
  }
  else if (pagename=='help'){
    this.IsClickedhelp=false;
  }
  }

  
  
    ngOnDestroy(){
      this.subscription.unsubscribe();
      this.highalertssubscription.unsubscribe();
      this.normalalertsubscription.unsubscribe();
    }
   
}
