import { SharedComponentsModule } from './modules/shared/shared-components.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Pipe, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { HeaderComponent } from './home/header/header.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/body/home.component'; import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsAdalAngular6Module, MsAdalAngular6Service, AuthenticationGuard } from 'microsoft-adal-angular6';
import { AppConfig } from './services/appconfig.service';
import { InsertAuthTokenInterceptor } from './services/helpers/insert-auth-token-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatNativeDateModule, MatDividerModule, MatFormFieldModule, MatTableModule, MatToolbarModule, MatTooltipModule, MatAutocompleteModule, MatInputModule, MatListModule,MatIconModule } from '@angular/material';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { MatDatepickerModule } from '@angular/material';
import { NgxPaginationModule } from 'ngx-pagination'; 
import { NgxSpinnerModule } from "ngx-spinner";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomModalModule } from './modules/shared/modal';
import { SpinnerInterceptor } from './modules/shared/spinnerinterceptor';
import {MatExpansionModule} from '@angular/material/expansion';

import {DatePipe} from '@angular/common';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup
import { MomentModule } from 'angular2-moment';
import { ApplicationFooterComponent } from './application-footer/application-footer.component';

import { ClickOutsideDirective } from './directive/click-outside.directive';
// import { AdvanceSearchComponent } from  './modules/advance-search/advance-search.component';


let appRoutes: Routes = [   {path:"home", component:HomeComponent, canActivate : [AuthenticationGuard], data:{title:'Home'}},
                            {path:"", redirectTo:"home", pathMatch:"full",data:{title:'Home'}},
                            {path:"admin", loadChildren: () => import("./modules/admin/admin.module").then(m=> m.AdminModule),data:{title:'Admin',showbreadcrumb:'false'}}                           
                            
                        ]

//ADAL authentication 
let adalConfig: any; // will be initialized by APP_INITIALIZER
export function msAdalAngular6ConfigFactory() {
  return adalConfig; // will be invoked later when creating MsAdalAngular6Service
}

// Loading config values into adal config
export function initializeApp(appConfig: AppConfig) {
  const promise = appConfig.load().then(() => {
    adalConfig = {
      tenant: AppConfig.settings.adalConfig.tenant,
      clientId: AppConfig.settings.adalConfig.clientId,
      redirectUri: window.location.origin,
      endpoints: AppConfig.settings.adalConfig.endpoints,
      navigateToLoginRequestUrl: false,
      cacheLocation: AppConfig.settings.adalConfig.cacheLocation
    };
  });
  return () => promise;
}

/**
 * Custom angular notifier options
 */
const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 62,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 3000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,   
    ClickOutsideDirective,
    ApplicationFooterComponent    
    ],
  imports: 
  [
    BrowserModule,
    // FlexLayoutModule,
    MatExpansionModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes,{useHash : false}), 
    FormsModule,
    ReactiveFormsModule, 
    HttpClientModule, 
    MsAdalAngular6Module ,
    MatNativeDateModule ,
    MatListModule,
    MatDatepickerModule,   
    MatDividerModule,
    CustomModalModule,
    DragDropModule,

    NgbModule,
    NgxPaginationModule,
    NotifierModule.withConfig(customNotifierOptions),
    MatToolbarModule,MatTooltipModule,MatAutocompleteModule, 
    NgxSpinnerModule,MatFormFieldModule,MatTableModule,MatInputModule,MatExpansionModule,MatIconModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
   SharedComponentsModule
   ],

    
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    MsAdalAngular6Service,
    {
      provide: 'adalConfig',
      useFactory: msAdalAngular6ConfigFactory,
      deps: []
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InsertAuthTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
  },
    AuthenticationGuard,
  
    DatePipe


  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
