import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  apiData: any;
  advanceSearchBtnDisable:boolean=false;
  simpleSearchVisible:boolean=false;
  constructor(private _http: HttpClient) { }

  public FetchLoggedInUser(userAlias:string) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('user/fetchloggedinuser', httpOptions).toPromise();
    return this.apiData;
  }

  public archiveHomeSearch(searchValue:string,argloggedinuser :string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('v1/getsimplesearcharchivedetails/'+searchValue+'/', httpOptions);
    return this.apiData;
  }
  public documentHomeSearch(searchValue:string,argloggedinuser :string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('v1/getsimplesearchrecorddetails/'+searchValue+'/'+argloggedinuser, httpOptions);
    return this.apiData;
  }
  public getActiveRoles(archivenumber:string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('ArchiveMetaData/GetActiveRoles?archivenumber=' + archivenumber, httpOptions);
    return this.apiData;
  }
  public getActiveRolesForRequestAccess(archivenumber:string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('ArchiveMetaData/GetActiveRolesForRequestTemporaryAccess?archivenumber=' + archivenumber, httpOptions);
    return this.apiData;
  }
 
  public GetArchiveAccessRequestCountForApproval(EmployeeUniqueIdentifier:string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('archive/GetArchiveAccessRequestCountForApproval', httpOptions);
    return this.apiData;
  } 

}


