import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-export-to-excel',
  templateUrl: './export-to-excel.component.html',
  styleUrls: ['./export-to-excel.component.css']
})
export class ExportToExcelComponent implements OnInit {

  @Input("columnFilters") columnFilters: any[];

  @Input("canDownload") canDownload: Boolean = true;
  @Output("generateReportClicked") generateReportClicked = new EventEmitter<any>();
  showColumnFiltersPane: boolean = false;

  constructor(private spinnerService: NgxSpinnerService, private notifier: NotifierService) { }

  ngOnInit() {
  }

  showFilterColumns() {
    this.showColumnFiltersPane = !this.showColumnFiltersPane;
  }

  generateReport() {
    if (this.columnFilters.filter(x => x.checked).length) {
      //this.showFilterColumns();
      this.generateReportClicked.emit(this.columnFilters);
      this.resetSelection(true);
    }
    else {
      this.notifier.notify("error", "Please select at least one column to export.");
    }

  }

  columnSelected(index: number) {
    (this.columnFilters[index])["checked"] = !(this.columnFilters[index])["checked"];
  }

  resetSelection(closePopup: boolean) {
    this.columnFilters.forEach(column => {
      column["checked"] = false;
    });

    if (closePopup) {
      this.showFilterColumns();
    }
  }

  generateReportWithAllColumns(){
    this.columnFilters.forEach(column => {
      column["checked"] = true;
    });
    this.generateReportClicked.emit(this.columnFilters);    
    this.resetSelection(false);
  }
}
