import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppConfig } from '../../services/appconfig.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { NotifierService } from "angular-notifier";
import { ModalService } from '../../modules/shared/modal';

//import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { HomePageService } from '../../services/homepage.service'
import { Subscription } from 'rxjs';
import { PubsubService } from '../../services/pubsub.service';
import { NgxSpinnerService } from 'ngx-spinner'
import { UserConfigSettingService } from '../../guards/user-role-guard.service';
import { ArchiveService } from '../../services/archive.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  currentUrl: string;
  goBackTooltip:string="Navigate to RM-Health Check";
  tableTooltip:string="Click here to view detail Report"
  UserName =this.adalSvc.LoggedInUserName;
  firstName=this.UserName.substring(0, this.UserName.lastIndexOf(","));
  lastName=this.UserName.substring(-1, this.UserName.lastIndexOf(","));
  userEmail = this.adalSvc.LoggedInUserEmail;
  userAlias = this.userEmail.substring(0, this.userEmail.lastIndexOf("@"));
  MouseHover: boolean = false;
  enablepopup: boolean = false;
  userConfig: any;
  configData:any;
  pendingSubData: any;
  accessRequestData: any;
  currentPageNumber: number = 1;
  pageSize: number = 25;
  requiringApprovalData: any;
  redColor: any;
  orangeColor: any;
  yellowColor: any;
  appendArchiveToGrid: boolean = false;
  archives: any[];
  checkForAudit : any[];
  hcrchild: any[];
  totalArchivesAssigned: number = 0;
  totalArchives: number = 0;
  configValue: any;
  featureValueForVerification: string = "Files waiting for verification";
  arrHcr = [];
  checkForNonAudits = [];
  checkForNonAudit = [];

  countForAccessRequests: any;
  employeeUniqueIdentifier: string;
  tooltipInfoSiteCheck: string = 'https://dev.archiveit.us.deloitte.com/';
  tooltipInfoCMISCheck: string = 'This check show CMIS Service check is up and running';

  readonly emptyStringInReport : string = " ";
  udmGridColumns: any =UDMGridColumns;
  emptyS3PathGridColumns: any = EmptyS3PathGridColumns;
  S3PathEmptyLabel = "Upload to S3 but S3 path EMPTY";

  columnFilters: any[] =
  [
    {
      "displayName": "UserAlias",
      "value": this.udmGridColumns.userAlias,
      "checked": true
    },
    {
      "displayName": "FileCount",
      "value": this.udmGridColumns.countByArchive,
      "checked": true
    },
    {
      "displayName": "Archive",
      "value": this.udmGridColumns.archiveNumber,
      "checked": true
    }
    ]

  s3PathColumnFilters: any[] =
    [
      {
        "displayName": "Archive number",
        "value": this.emptyS3PathGridColumns.archiveNumber,
        "checked": true
      },
      {
        "displayName": "Client name",
        "value": this.emptyS3PathGridColumns.clientName,
        "checked": true
      },
      {
        "displayName": "File/Record name",
        "value": this.emptyS3PathGridColumns.recordName,
        "checked": true
      },
      {
        "displayName": "Source",
        "value": this.emptyS3PathGridColumns.source,
        "checked": true
      }
    ]
  
  common: any;


  constructor(private archiveService: ArchiveService,private router: Router, private adalSvc: MsAdalAngular6Service, private notifier: NotifierService
    , private modalService: ModalService,
      private SpinnerService: NgxSpinnerService,
      private _userConfig:UserConfigSettingService,
     private homepageSvc: HomePageService, private pubsub: PubsubService,
     private sharedService: SharedService ) {


  }

  public pubsubcount :number = 0;


  ngOnInit() {

    this.getConfigValueForFileVerification();
    let element3 = document.getElementById("applicationFooter_ID");
    element3.classList.add("application-footer-b-0");
    this.userConfig = this._userConfig.FetchLoggedInUser(this.userAlias);
    this.getHCRReport();
    console.log(AppConfig.settings.env)
    console.log("MouseOver:" + this.MouseHover);
    this.homepageSvc.advanceSearchBtnDisable=false;
    this.homepageSvc.simpleSearchVisible=false;
    let element2 = document.getElementById("appRootScroll");
    element2.classList.remove("app-root-scroll");



     }
  ngOnDestroy() {
    let element3 = document.getElementById("applicationFooter_ID");
    element3.classList.remove("application-footer-b-0");
    let element = document.getElementById("customBreadcrumb");
    element.classList.remove("home-breadcrumb");
    let element2 = document.getElementById("appRootScroll");
    element2.classList.add("app-root-scroll");
    let element1 = document.getElementById("ulBreadcrumbDetails");
    element1.classList.remove("custombreadcrumb-display");
  }
  OnClick(PageUrl) {
    this.homepageSvc.advanceSearchBtnDisable=false;
    this.homepageSvc.simpleSearchVisible=false;
    this.router.navigate(["/" + PageUrl + ""]);
  }

   getHCRReport(){ 
    this.archiveService.GetHCRReport(1,25
    ).subscribe(
      data=>{
        this.requiringApprovalData = data;

        if(this.requiringApprovalData.length > 0) {
        // this.redColor = data[0].countRed;
        // this.orangeColor = data[0].countOrange;
        // this.yellowColor = data[0].countYellow;
      }

      for(let i =0; i< data.length;i++){
        if(data[i].hcrCheckType.toLowerCase() == this.featureValueForVerification.toLowerCase()){
          this.configValue = (this.configValue == undefined || this.configValue == '') ? localStorage.getItem("ConfigValue"):this.configValue;
          data[i].hcrCheckType = data[i].hcrCheckType + ' longer than ' + this.configValue + ' mins';
        }
      }
        console.log(data);
        this.archives = data ? data.slice(0,12) : [];
        this.hcrchild = data ? data.slice(12,15) : [];

        this.archives = this.archives;
        this.common = data.filter((e:any)=> e.featureType == "Common" ); 
        this.checkForAudit = this.archives.filter((e:any)=> e.featureType == "Audit" ); 
        this.checkForNonAudit = data.filter((e:any)=> e.featureType == "Non-Audit" );  

        if(this.requiringApprovalData.length > 0) {
        this.totalArchives = this.archives[0]["count"];
      }
        console.log('array length', this.totalArchives);
        this.SpinnerService.hide();
      }
    )
  }

  getConfigValueForFileVerification(){
    this.archiveService.GetConfigValueForFileVerification(4).subscribe(
      data => {
        localStorage.setItem("ConfigValue", data);
        this.configValue = data;
        console.log('Config Value: '+ this.configValue);
      }
    )
  }

  getDetails() {   
    this.archiveService.GetPendingSubmission(this.currentPageNumber, this.pageSize).subscribe(
      data => {
        this.requiringApprovalData = data;

        if(this.requiringApprovalData.length > 0) {
        this.redColor = data[0].countRed;
        this.orangeColor = data[0].countOrange;
        this.yellowColor = data[0].countYellow;
      }
        if (this.appendArchiveToGrid && data) {
          this.archives = this.archives.concat(data);
        }
        else {
          this.archives = data ? data : [];
        }
        if(this.requiringApprovalData.length > 0) {
        this.totalArchives = this.archives[0]["count"];
      }
        console.log('array length', this.totalArchives);
        this.SpinnerService.hide();
      }
    );
  }
  openSubmitArchiveModal(modalType: string) {
      this.modalService.openWithCustomWidth(modalType, "1200");
  }
  closeModalDialog(Action) {
    this.modalService.close(Action);
  }

  generateReportClicked(checkAudit : any){
    let udmForReport: any[] = [];
    let filterdColumns = (this.columnFilters.filter(x => x.checked));
    if(checkAudit == "Audit" || checkAudit== "common" )
    {    this.archiveService.GetUDMDateForExcelExport().subscribe(
      data => {
        if (data.length > 0) {
          data.forEach(element => {
            let udmData = {};
            filterdColumns.forEach(column => {

              if (column["value"] == this.udmGridColumns.userAlias) {
                udmData[this.udmGridColumns[this.udmGridColumns.userAlias]] = element.userAlias || this.emptyStringInReport;
              }
              if (column["value"] == this.udmGridColumns.countByArchive) {
                udmData[this.udmGridColumns[this.udmGridColumns.countByArchive]] = element.countByArchive || this.emptyStringInReport;
              }
              if (column["value"] == this.udmGridColumns.archiveNumber) {
                udmData[this.udmGridColumns[this.udmGridColumns.archiveNumber]] = element.archiveNumber || this.emptyStringInReport;
              }
            });
            udmForReport.push(udmData);
          });
          if (udmForReport && udmForReport.length) {
            this.sharedService.generateExcel("UDM Data", "UDM", filterdColumns.map(x => x.displayName), udmForReport);
          }
        }
      });
    }

      if(checkAudit == "nonAudit"){
        this.archiveService.GetUDMNonAuditDateForExcelExport().subscribe(
          data => {
            if (data.length > 0) {
              data.forEach(element => {
                let udmData = {};
                filterdColumns.forEach(column => {
    
                  if (column["value"] == this.udmGridColumns.userAlias) {
                    udmData[this.udmGridColumns[this.udmGridColumns.userAlias]] = element.userAlias || this.emptyStringInReport;
                  }
                  if (column["value"] == this.udmGridColumns.countByArchive) {
                    udmData[this.udmGridColumns[this.udmGridColumns.countByArchive]] = element.countByArchive || this.emptyStringInReport;
                  }
                  if (column["value"] == this.udmGridColumns.archiveNumber) {
                    udmData[this.udmGridColumns[this.udmGridColumns.archiveNumber]] = element.archiveNumber || this.emptyStringInReport;
                  }
                });
                udmForReport.push(udmData);
              });
              if (udmForReport && udmForReport.length) {
                this.sharedService.generateExcel("UDM Data", "UDM", filterdColumns.map(x => x.displayName), udmForReport);
              }
            }
          });

      }

  }


  generateEmptyS3PathReport(checkAudit: any) {
    let emptyS3RcordsForReport: any[] = [];
    let filterdColumns = (this.s3PathColumnFilters.filter(x => x.checked));

    if (checkAudit == "nonAudit") {
      this.archiveService.GetNonAuditEmptyS3PathExcelExport().subscribe(
        data => {
          if (data.length > 0) {
            data.forEach(element => {
              let emptyS3PathRecordData = {};
              filterdColumns.forEach(column => {
                if (column["value"] == this.emptyS3PathGridColumns.archiveNumber) {
                  emptyS3PathRecordData[this.emptyS3PathGridColumns[this.emptyS3PathGridColumns.archiveNumber]] = element.archiveNumber || this.emptyStringInReport;
                }
                if (column["value"] == this.emptyS3PathGridColumns.clientName) {
                  emptyS3PathRecordData[this.emptyS3PathGridColumns[this.emptyS3PathGridColumns.clientName]] = element.clientName || this.emptyStringInReport;
                }
                if (column["value"] == this.emptyS3PathGridColumns.recordName) {
                  emptyS3PathRecordData[this.emptyS3PathGridColumns[this.emptyS3PathGridColumns.recordName]] = element.recordName || this.emptyStringInReport;
                }
                if (column["value"] == this.emptyS3PathGridColumns.source) {
                  emptyS3PathRecordData[this.emptyS3PathGridColumns[this.emptyS3PathGridColumns.source]] = element.source || this.emptyStringInReport;
                }
              });
              emptyS3RcordsForReport.push(emptyS3PathRecordData);
            });
            if (emptyS3RcordsForReport && emptyS3RcordsForReport.length) {
              this.sharedService.generateExcel("Empty S3 path records data", "EmptyS3PathRecords", filterdColumns.map(x => x.displayName), emptyS3RcordsForReport);
            }
          }
        });
    }
  }

}

export enum UDMGridColumns
{
  userAlias = 1,
  countByArchive = 2,
  archiveNumber = 3
}

export enum EmptyS3PathGridColumns {
  archiveNumber = 1,
  clientName = 2,
  recordName = 3,
  source=4
}
