import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { mergeMap, catchError } from 'rxjs/operators';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AppConfig } from '../appconfig.service';
import { PubsubService } from '../pubsub.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';

@Injectable()
export class InsertAuthTokenInterceptor implements HttpInterceptor {

    notifier: NotifierService;
    constructor(private adal: MsAdalAngular6Service, private pubsub : PubsubService, private SpinnerService : NgxSpinnerService, private config:AppConfig, private _notifier: NotifierService) {
        this.notifier = _notifier;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        this.config.refrshAppSettings();
        // get api url from adal config
        const resource = this.adal.GetResourceForEndpoint(AppConfig.settings.adalConfig.endpoints.api);

        if (!resource || !this.adal.isAuthenticated) {
            //renew AD token silently
            this.adal.RenewToken(AppConfig.settings.adalConfig.endpoints.api);
            console.log("Token renewal done, iSAuthenticated : " + this.adal.isAuthenticated + " "  + Date().toString());
        }

        // merge the bearer token into the existing headers
        return this.adal.acquireToken(resource).pipe(
            mergeMap((token: string) => {

                localStorage.setItem("adalToken", token);
                var authorizedRequest;
                if (req.url.indexOf('localhost') >= 0 || req.url.indexOf('X-Amz-Security-Token') >= 0 || req.url.indexOf('x-amz-security-token') >= 0) {
                    authorizedRequest = req.clone({
                        url: `${req.url}`
                    });
                }else 
                {
                    authorizedRequest = req.clone({
                        headers: req.headers.set('Authorization', `Bearer ${token}`).set('Content-Type', 'application/json'),
                        url: `${AppConfig.settings.endpoints.apigatewayurl}/${req.url}`
                    });

                }

                // const headers = new HttpHeaders({
                //     'Authorization': `Bearer ${token}`,
                //     'Content-Type': 'application/json'
                //   });

                // const authorizedRequest = req.clone({headers});
                return next.handle(authorizedRequest).pipe(catchError(error => {

                    this.handlehttpException(error);
                    throw error;
                }));

            }));
    }

    handlehttpException(error: any) {
        this.SpinnerService.hide();
        console.log("Error while calling API");
        console.log(error.error.CorrelationId);
        if (error.error && error.error.CorrelationId) {
            console.log("CorrelationId: " + error.error.CorrelationId.replace('Root','').replace('=','').replaceAll('-',''));
           /* this.notifier.notify(
                "error", "Error: " + error.error.CorrelationId.replace('Root','').replace('=','').replaceAll('-','')
            );*/
            this.notifier.notify(
                "error", "Something went wrong, Please try again"
            );
        }
        else {
            return "";
        }
    }
}
