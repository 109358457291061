import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';


import { PaginationComponent } from './pagination/pagination.component';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { ExportToExcelComponent } from './export-to-excel/export-to-excel.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { CustomModalModule } from '../shared/modal';
import { RouterModule } from '@angular/router';


import { NgCircleProgressModule } from 'ng-circle-progress';

import {
  MatMenuModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,  
  MatFormFieldModule, 
  MatOptionModule, 
  MatInputModule, 
  MatTableModule, 
  MatDatepickerModule, 
  MatDividerModule, 
  MatExpansionModule
} from "@angular/material";
import { AngularEditorModule } from '@kolkov/angular-editor';

import { ResizableModule } from "angular-resizable-element";
import { MatAutocompleteModule } from '@angular/material/autocomplete';


const customNotifierOptions: NotifierOptions = {
    position: {
      horizontal: {
        position: "right",
        distance: 12
      },
      vertical: {
        position: "top",
        distance: 12,
        gap: 10
      }
    },
    theme: "material",
    behaviour: {
      autoHide: 3000,
      onClick: "hide",
      onMouseover: "pauseAutoHide",
      showDismissButton: true,
      stacking: 4
    },
    animations: {
      enabled: true,
      show: {
        preset: "slide",
        speed: 300,
        easing: "ease"
      },
      hide: {
        preset: "fade",
        speed: 300,
        easing: "ease",
        offset: 50
      },
      shift: {
        speed: 300,
        easing: "ease"
      },
      overlap: 150
    }
  };
  
@NgModule({
    imports: [
      CommonModule,
      NgbModule, 
      CustomModalModule, 
      FormsModule,  
      ReactiveFormsModule, 
      
      NotifierModule.withConfig(customNotifierOptions), 
      NgxSpinnerModule,
      RouterModule,
      MatButtonModule,
      MatButtonToggleModule,
      MatMenuModule,
      AngularEditorModule,
      ResizableModule,
      MatAutocompleteModule,
      MatCheckboxModule,  
      MatFormFieldModule, 
      MatOptionModule, 
      MatInputModule, 
      MatTableModule, 
      MatDatepickerModule, 
      MatDividerModule, 
      MatExpansionModule,
      NgCircleProgressModule.forRoot({

        radius: 100,
        outerStrokeWidth: 16,
        innerStrokeWidth: 8,
        animationDuration: 300,
        "title": [
            "working",
            "in",
            "progress"

        ],
        maxPercent: 100,
        showSubtitle: false,
        showUnits: false
    })
    ],
    declarations: [
      
      PaginationComponent,
      ExportToExcelComponent

    ],
    exports: [
      
      PaginationComponent,
      ExportToExcelComponent,
        
      NgbModule
    ]
})
export class SharedComponentsModule { }