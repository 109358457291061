import { Component, OnInit } from '@angular/core';
import { ArchiveService } from '../services/archive.service';
import { FooterConfigurationDataModel } from '../models/footer-configuration-data.model';

@Component({
  selector: 'application-footer',
  templateUrl: './application-footer.component.html',
  styleUrls: ['./application-footer.component.css']
})
export class ApplicationFooterComponent implements OnInit {

  // public properties
  public footerConfigurationData: FooterConfigurationDataModel[] = [];

  constructor(private _archiveService: ArchiveService) { }

  ngOnInit(): void {
    this.getFooterConfigurationData();
  }

  public getFooterConfigurationData(): void {
    this._archiveService.GetFooterConfigurationData()
      .subscribe(data => {
        this.footerConfigurationData = data.filter((e:any)=> e.id != 4);
        console.log(this.footerConfigurationData);
      }),
      (err) => (console.log(err));
  }

  public openLink(item: FooterConfigurationDataModel): void {
    window.open(item.link, "_blank");
  }
}
