import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomePageService } from '../services/homepage.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root'
})
export class UserConfigSettingService {

    userConfig: any;
    hasUserDetails: boolean = false;
    loggedInUserData: any;

    constructor(private _http: HttpClient, private handler: HttpBackend, private SpinnerService: NgxSpinnerService, private archiveHomeSvc: HomePageService) {

        this.userConfig = {
            firstName: "",
            lastName: "",
            loggedInUserName: "",
            isAdmin: false,
            isGlobalReader: false,
            isRCO: false,
            initials: "",
            loggedInUserNameGreeting: "",
            IsManager: false,
            IsPPD: false,
            Title: ""
        };

    }

    FetchLoggedInUser(userAlias: string) {
        if (!this.hasUserDetails) {
            this.hasUserDetails = true;
            this.SpinnerService.show();
            this.archiveHomeSvc.FetchLoggedInUser(userAlias).then(
                (loggedInUserData) => {
                    if (loggedInUserData != null) {
                        this.hasUserDetails = true;
                        this.userConfig.firstName = loggedInUserData[0].firstName;
                        this.userConfig.lastName = loggedInUserData[0].lastName;
                        this.userConfig.loggedInUserName = this.userConfig.firstName + ' ' + this.userConfig.lastName;
                        this.userConfig.loggedInUserNameGreeting = 'Hello,' + ' ' + this.userConfig.loggedInUserName;
                        this.userConfig.initials = this.userConfig.loggedInUserName.split(' ').map(n => n[0]).join('').substring(0, 2);
                        this.userConfig.IsManager = loggedInUserData[0].isManager;
                        this.userConfig.IsPPD = loggedInUserData[0].isPPD;
                        this.userConfig.Title = loggedInUserData[0].title;
                        //check admin user or not
                        loggedInUserData.forEach(element => {
                            if (element.employeeRoleId == 15) {
                                this.userConfig.isAdmin = true;
                                //localStorage['isAdmin'] = JSON.stringify(this.userConfig.isAdmin);
                            }
                            if (element.employeeRoleId == 22) {
                                this.userConfig.isGlobalReader = true;
                            }
                            if (element.employeeRoleId == 8) {
                                this.userConfig.isRCO = true;
                            }
                            if (element.employeeRoleId == 32) {
                                this.userConfig.isHPT = true;
                            }
                        });
                        this.SpinnerService.hide();
                        return this.userConfig;
                    }
                });
        } else {
            return of(this.userConfig);
        }
    }

}