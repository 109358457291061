import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AnyLengthString } from 'aws-sdk/clients/comprehend';
import { UserActions } from '../models/archive-role.model';

@Injectable({
  providedIn: 'root'
})
export class PubsubService {

  private subject = new Subject<any>();

  private normalalertsubject = new Subject<any>();

  private highalertsubject = new Subject<any>();

  private form3283Ssubject = new Subject<any>();

  private form3283Status = new Subject<any>();

  private sessionExtensionSubject = new Subject<any>();

  private edcdsubject = new Subject<any>();

  private roleMapping = new UserActions();

  sendNumber(number: number) {
    this.subject.next({ text: number });
  }
  getNumber(): Observable<any> {
    return this.subject.asObservable();
  }

  sendduedate(updatedcd: boolean) {
    this.subject.next({ text: updatedcd });
  }

  getduedate(): Observable<any> {
    return this.subject.asObservable();
  }

  sendNormalAlertsCount(number: any) {
    this.normalalertsubject.next({ text: number });
  }

  getNormalAlertsCount(): Observable<any> {
    return this.normalalertsubject.asObservable();
  }

  sendHighAlertsCount(number: any) {
    this.highalertsubject.next({ text: number });
  }

  getHighAlertsCount(): Observable<any> {
    return this.highalertsubject.asObservable();
  }

  sendForm3283SStatus(Status: any) {
    this.form3283Ssubject.next({ text: Status });
  }

  getForm3283SStatus(): Observable<any> {
    return this.form3283Ssubject.asObservable();
  }

  SessionExtentionRequest() {
    this.sessionExtensionSubject.next({});
  }
  sessionExtentionNotification(): Observable<any> {
    return this.sessionExtensionSubject.asObservable();
  }

  sendFormSubmitNotifier(formstatus: string) {
    this.form3283Status.next({ text: formstatus });
  }
  getFormSubmitNotifier(): Observable<any> {
    return this.form3283Status.asObservable();
  }

  public setRoleMappingResult(roles: UserActions) {
    this.roleMapping = roles;
  }
  public getRoleMappingResult() {
    return this.roleMapping;
  }

}
