import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginuserdetailsService {
  UserName =this.adalSvc.LoggedInUserName; 
  firstName=this.UserName.substring(0, this.UserName.lastIndexOf(","));
  lastName=this.UserName.substring(-1, this.UserName.lastIndexOf(","));
  userEmail = this.adalSvc.LoggedInUserEmail;
  userAlias = this.userEmail.substring(0, this.userEmail.lastIndexOf("@"));

  constructor(private _http: HttpClient,private adalSvc: MsAdalAngular6Service) { 
  
    
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'

    }),
  }
   public GetUserAlias() {
    return this.userAlias;
  }
  public GetUserName(){
    return this.UserName;
  }
}

