import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject, of } from 'rxjs';
import { UserActions } from '../models/archive-role.model';
import { FooterConfigurationDataModel } from '../models/footer-configuration-data.model';

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  data: any;
  apiData: any;
  create: any;
  useralias: string;
  result: string;
  wbsinfo: any;
  roles: any;
  success: Observable<any>;
  UpdateArchive: any;
  archiveIdChildVal: any;
  private archiveMessageSource = new BehaviorSubject("");
  archiveName = this.archiveMessageSource.asObservable();
  private archiveDescriptionSource = new BehaviorSubject("");
  archiveDescription = this.archiveDescriptionSource.asObservable();
  private archiveRolesAndActions = new BehaviorSubject("");
  archiveRolesInfo = this.archiveRolesAndActions.asObservable();

  roleMapping = new UserActions();


  constructor(private _http: HttpClient) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'

    }),

  }



  public GetPendingSubmission(pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('dashboard/getmyawaitingsubmissiondashboarddata?PageNumber=' + pageNumber
      + '&PageSize=' + pageSize, httpOptions);
    return this.apiData;
  }

   public GetHCRReport(pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('hcr/GetHCRReports?PageNumber=' + pageNumber
    + '&PageSize=' + pageSize , httpOptions);
    return this.apiData;
  }

  public GetUDMDateForExcelExport(): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('hcr/UdmExportToExcel', httpOptions);
    return this.apiData;
  }
  
  public GetUDMNonAuditDateForExcelExport(): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('hcr/UdmNonAuditExportToExcel', httpOptions);
    return this.apiData;
  }

  public GetNonAuditEmptyS3PathExcelExport(): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    this.apiData = this._http.get<any[]>('hcr/GetNonAuditEmptyS3PathExcelExport', httpOptions);
    return this.apiData;
  }


  public GetConfigValueForFileVerification(configId: number): Observable<any> {
    const httpOptions:Object = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
      }),
      responseType: 'text'
   }
    this.apiData = this._http.get<any>('hcr/GetConfigValueForFileVerification?&configId=' + configId, httpOptions);
    return this.apiData;
  }

  public ExportToExcel(pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }) };
    this.apiData = this._http.get<any[]>('archive/ArchiveAccessRequestsDetails?PageNumber=' + pageNumber
      + '&PageSize=' + pageSize, httpOptions);
    return this.apiData;
  }


  public GetFooterConfigurationData(): Observable<FooterConfigurationDataModel[]> {
    if (!sessionStorage.getItem("footerConfigurationData")) {
      const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
      var footerConfigurationData = this._http.get<FooterConfigurationDataModel[]>("user/GetFooterConfigurationData", httpOptions);
      footerConfigurationData.subscribe(data => {
        sessionStorage.setItem("footerConfigurationData", JSON.stringify(data))
      });
      return footerConfigurationData;
    }
    else {
      var dataString = sessionStorage.getItem("footerConfigurationData");
      console.log(<FooterConfigurationDataModel[]>JSON.parse(dataString));
      return of(<FooterConfigurationDataModel[]>JSON.parse(dataString));
    }
  }
  }
